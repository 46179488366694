<template>
  <div>
    <div v-if="submitting">
      <div v-if="status === 'processing' || status === 'error'">
        <div v-if="status === 'processing'">
          <p>Please send your payment to this number {{payment_phone}}</p>
          <p>After your payment is successful your Subscription plan will be updated.</p>
          <p class="text-info">Please be aware that the process might take sometime. We will try to be as first as possible.</p>
        </div>
        <div v-else>
          <p class="text-danger">Error. Please try again later</p>
        </div>
      </div>
      <div v-else class="min-height-164px position-relative">
        <div class="position-absolute start-0 top-0 w-100">
          <div class="loader">Loading...</div>
        </div>
        <p>{{status}}</p>
      </div>
    </div>
    <div v-else>
      <div v-if="this.current_plan === 'profession'">
        <p>You have the best subscription option available. Please, contact our customer service to get more possibilities.</p>
      </div>
      <div v-else class="mb-3">
        <p class="mb-1">Select subscription plan. Check pricing reference below.</p>
        <div v-for="plan in plans" :key="plan">
          <input type="radio" :value="plan" :id="plan" class="form-check-input" v-model="subscription.subscription_plan" />
          <label :for="plan" class="form-check-label text-capitalize">{{plan}}</label>
        </div>
        <div class="my-3">
          <p class="mb-2 text-decoration-underline">Payment submissions are accepted everyday between 10 am - 6 pm.</p>
          <div class="mb-3">
            <button class="btn btn-info btn-sm rounded-0" type="button" data-bs-toggle="modal" data-bs-target="#paymentInfoModal">Read more about the payment process</button>
          </div>
          <label for="phone-number" class="form-label">
            <span class="d-block">Your mobile phone number that you will going to make the payment with</span>
          </label>
          <input type="number" v-model="subscription.phone" @keyup="validatePhone"  class="form-control" id="phone-number" placeholder="7221231234">
        </div>
        <div class="mt-3">
          <button @click="submit" :disabled="!phone_valid" class="btn btn-primary rounded-0">Proceed</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  props: ["sub_plans", "current_plan", "id"],
  data() {
    return {
      submitting: false,
      plans: [],
      payment_phone: "",
      subscription: {
        subscription_plan: "",
        phone: "",
      },
      phone_valid: false,
      status: "Submitting..."
    }
  },
  methods: {
    submit(){
      this.submitting = true;
      axios.put(`/member/subscriptions/${this.id}`, this.subscription)
          .then(response => {
            let {status, phone} = response["data"];
            if(status === 'processing'){
              this.status = status;
              this.payment_phone = phone;
            }else {
              this.status = "Error"
            }
          })
          .catch(e => console.log(e))
    },
    validatePhone($event){
      let regex = /[1-9]/
      let test = regex.test(this.subscription.phone)
      this.phone_valid = test && this.subscription.phone.length > 9;
    },
  },
  created() {
    if(this.sub_plans){
      this.plans = this.sub_plans;
      this.subscription.subscription_plan = this.sub_plans[0]
    }
  }
}
</script>