<template>
  <div>
    <div v-if="submitting">
      <div class="min-height-164px position-relative">
        <div class="position-absolute start-0 top-0 w-100">
          <div class="loader">Loading...</div>
        </div>
        <p>{{status}}</p>
      </div>
    </div>
    <div v-else>
      <div v-if="this.current_plan === 'profession'">
        <p>You have the best subscription option available. Please, contact our customer service to get more possibilities.</p>
      </div>
      <div v-else>
        <p>Select subscription plan. Check pricing reference below.</p>
        <div v-for="plan in plans" :key="plan">
          <input type="radio" :value="plan" :id="plan" class="form-check-input" v-model="subscription.subscription_plan" />
          <label :for="plan" class="form-check-label text-capitalize">{{plan}}</label>
        </div>
        <div class="mb-3">
          <label for="phone-number" class="form-label">Your mobile phone number (Safaricom only) - Format (7221231234)</label>
          <input type="number" v-model="subscription.phone" @keyup="validatePhone"  class="form-control" id="phone-number" placeholder="7221231234">
          <small v-if="!phone_valid" class="text-danger">Not valid</small>
          <small v-else class="text-success">Valid</small>
        </div>
        <div class="mt-3">
          <button @click="submit" :disabled="!phone_valid" class="btn btn-primary rounded-0">Proceed</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  props: ["sub_plans", "current_plan", "id"],
  data() {
    return {
      submitting: false,
      plans: [],
      subscription: {
        subscription_plan: "",
        phone: "",
      },
      phone_valid: false,
      status: "Submitting..."
    }
  },
  methods: {
    submit(){
      this.submitting = true;
      axios.put(`/member/subscriptions/${this.id}`, this.subscription)
      .then(response => {
        let {status} = response;
        if(status === 'processing'){
          this.status = "Processing..."
          this.updateStatus()
        }
      })
      .catch(e => console.log(e))
    },
    validatePhone($event){
      let regex = /[1-9]/
      let test = regex.test(this.subscription.phone)
      this.phone_valid = test && this.subscription.phone.length === 9;
    },
    updateStatus(){
      let timer = setInterval(() => {
        axios.get(`/member/subscriptions/${this.id}/payment-status`)
            .then(response => {
              let {status} = response;
              if(status !== 'error' || status !== 'completed'){
                this.status = status
              }else {
                clearInterval(timer);
              }
            })
            .catch(e => console.log(e))
      }, 10000)
    }
  },
  created() {
    if(this.sub_plans){
      this.plans = this.sub_plans;
      this.subscription.subscription_plan = this.sub_plans[0]
    }
  }
}
</script>