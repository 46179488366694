<template>
  <div class="mb-3">
    <div class="row">
      <div v-for="(photo, index) in saved_photos" :key="photo.id" class="col-sm-6 col-md-4 mb-2">
        <div class="w-100 full-height d-flex flex-column">
          <div class=" border border-gray22 flex-grow-1">
            <img :src="photo.url" class="img-fluid" />
          </div>
          <div class="d-grid my-2">
            <button class="btn btn-danger btn-sm rounded-0 fs-12 my-2" @click="removeSavedPhoto($event, photo.id)">Remove photo</button>
          </div>
        </div>
      </div>
      <div v-for="(photo, index) in photos" :key="index" class="col-sm-6 col-md-4 mb-2">
        <div class="w-100 full-height d-flex flex-column">
          <div class=" border border-gray22 flex-grow-1">
            <img :src="showPhotoPreview(photo)" class="img-fluid" />
          </div>
          <div class="d-grid my-2">
            <button class="btn btn-danger btn-sm rounded-0 fs-12 my-2" @click="removePhoto($event, photo)">Remove photo</button>
          </div>
        </div>
      </div>
    </div>
    <label for="photo-previewer-input" class="btn btn-dark rounded-pill px-4">Add Photo</label>
    <input type="file" class="d-none" @change="updatePhotos" id="photo-previewer-input" multiple="multiple" accept="image/jpeg, image/png, image/webp, image/tiff, image/svg+xml" />
    <input ref="photo_multiple" type="file" class="d-none" name="files[]" multiple="multiple" accept="image/jpeg, image/png, image/webp, image/tiff, image/svg+xml" />
    <input ref="old_photos" type="text" class="d-none" name="old_photos" />
    <p class="text-danger mt-3">{{message}}</p>
  </div>
</template>
<script>
export default {
  props: ["apartment_photos"],
  data(){
    return {
      photos: [],
      message: "",
      saved_photos: [],
      photos_to_be_removed: [],
      old_photos: "",
      max_uploads: 0
    }
  },
  methods: {
    updatePhotos(event){
      if(this.message){
        this.message = "";
      }

      let files = event.target.files;
      if(files && files.length > 0){
        for(let i = 0; i < files.length; i++){
          let file = files[i];
          let is_uploaded = this.checkIsFileAvailable(file);
          if(is_uploaded){
            this.message = `File (${is_uploaded.name}) has been already uploaded.`;
          }else {
            if(this.max_uploads + this.photos.length > 5){
              this.message = "Uploaded maximum photos allowed."
              return;
            }else {
              this.photos.unshift(file)
              this.$refs.photo_multiple.files = this.fileListItems(this.photos);
            }
          }
        }
      }
    },
    removePhoto(event, photo){
      event.preventDefault();
      if(this.message){
        this.message = "";
      }
      this.photos = this.photos.filter(({name}) => name !== photo.name)
      this.$refs.photo_multiple.files = this.fileListItems(this.photos);
    },
    showPhotoPreview(photo){
      let url = URL.createObjectURL(photo)
      return url;
    },
    checkIsFileAvailable(file){
      let file_name = file.name;
      return this.photos.find(({name}) => name === file_name)
    },
    fileListItems (files) {
      let b = new ClipboardEvent("").clipboardData || new DataTransfer()
      for (let i = 0, len = files.length; i<len; i++) b.items.add(files[i])
      return b.files
    },
    removeSavedPhoto(event, photo_id){
      event.preventDefault();
      this.photos_to_be_removed.push(photo_id);
      this.saved_photos = this.saved_photos.filter((sv) => sv.id !== photo_id)
      this.max_uploads = this.saved_photos.length
      this.$refs.old_photos.value = this.photos_to_be_removed.join(",");
    }
  },
  created() {
    if(this.apartment_photos){
      this.saved_photos = this.apartment_photos;
      this.max_uploads = this.saved_photos.length;
    }
  }
}
</script>