<template>
  <div class="dropdown">
    <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fa-solid fa-list"></i> {{active_item}}
    </a>

    <ul v-if="simple_search" class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('Price low to high')">Price low to high</a></li>
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('Price high to low')">Price high to low</a></li>
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('Recently Added')">Recently Added</a></li>
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('Added Descending')">Added Descending</a></li>
    </ul>

    <ul v-else class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('Price low to high')">Price low to high</a></li>
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('Price high to low')">Price high to low</a></li>
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('New announcements first')">New announcements first</a></li>
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('Old announcements first')">Old announcements first</a></li>
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('Smallest area first')">Smallest area first</a></li>
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('Biggest area first')">Biggest area first</a></li>
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('Smallest number of guests')">Smallest number of guests</a></li>
      <li><a class="dropdown-item" href="#" @click="updateSortingDate('Largest number of guests')">Largest number of guests</a></li>
    </ul>
  </div>
</template>
<script>
import queryString from 'query-string'
export default {
  props: ["active_item", "simple_search"],
  data(){
    return {

    }
  },
  methods: {
    updateSortingDate(value){
      let queryToObj = queryString.parse(location.search);
      queryToObj["order_by"] = value;
      let query = queryString.stringify(queryToObj)
      location.href = `${location.pathname}?${query}#apt-cards`;
    }
  }
}
</script>