<template>
  <div>
    <ul class="list-unstyled d-flex justify-content-between flex-wrap">
      <li v-for="facility in mainFacilities" :key="facility.name" v-if="facility.val" class="w-columns-30 mb-3">
        <span class="d-inline-block me-2">
          <i :class="facility.icon + ' text-primary'"></i>
        </span>
        <span v-if="facility.name === 'Area'" >{{facility.val}}m<sup>2</sup> {{facility.name}}</span>
        <span v-else>{{facility.val}} {{facility.name}}</span>
      </li>

      <li v-for="facility in facilities" :key="facility.name" v-if="facility.val" class="w-columns-30 mb-3">
        <span class="d-inline-block me-2">
          <i :class="facility.icon + ' text-primary'"></i>
        </span>
        <span>{{facility.name}}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['apartment'],
  data(){
    return {
      mainFacilities: [],
      facilities: []
    }
  },
  created() {
    if(this.apartment){
      this.mainFacilities = [
        {name: 'Bedroom', val: this.apartment.bedroom, icon: 'fa-solid fa-bed'},
        {name: 'Bathroom', val: this.apartment.bathrooms, icon: 'fa-solid fa-shower'},
        {name: 'Guest', val: this.apartment.guest, icon: 'fa-solid fa-user-vneck'},
        {name: 'Area', val: this.apartment.area, icon: 'fa-solid fa-ruler-combined'},
      ]
      this.facilities = [
        {name: 'Hotel', val: this.apartment.hotel, icon: 'fa-solid fa-hotel'},
        {name: 'Breakfast', val: this.apartment.breakfast, icon: 'fa-solid fa-mug-saucer'},
        {name: 'Television', val: this.apartment.television, icon: 'fa-solid fa-tv'},
        {name: 'Wash Service', val: this.apartment.wash_service, icon: 'fa-solid fa-shirt'},
        {name: 'Lift', val: this.apartment.lift, icon: 'fa-solid fa-elevator'},
        {name: 'Balcony', val: this.apartment.balcony, icon: 'fa-solid fa-house-chimney-window'},
        {name: 'Arrival or Departure pickup', val: this.apartment.arrival_departure_pickup, icon: 'fa-solid fa-bus'},
        {name: 'Suitable for disabled', val: this.apartment.suitable_for_disabled, icon: 'fa-solid fa-wheelchair'},
        {name: 'Swimming pool', val: this.apartment.swimming_pool, icon: 'fa-solid fa-person-swimming'},
        {name: 'Sauna', val: this.apartment.sauna, icon: 'fa-solid fa-hot-tub-person'},
        {name: 'Children playground', val: this.apartment.play_ground, icon: 'fa-solid fa-person-walking'},
        {name: 'Sports center', val: this.apartment.sports_center, icon: 'fa-solid fa-futbol'},
        {name: 'Fitness center', val: this.apartment.fitness_center, icon: 'fa-solid fa-dumbbell'},
        {name: 'Smoking', val: this.apartment.smoking, icon: 'fa-solid fa-ban-smoking'},
        {name: 'Garage/Parking', val: this.apartment.garage, icon: 'fa-solid fa-warehouse'},
        {name: 'Single floor layout', val: this.apartment.single_floor_layout, icon: 'fa-solid fa-building'},
        {name: 'Fully furnished', val: this.apartment.furnished, icon: 'fa-solid fa-couch'},
        {name: 'Mosque', val: this.apartment.mosque, icon: 'fa-solid fa-mosque'}
      ]
    }
  }
}
</script>