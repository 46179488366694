<template>
  <nav ref="navbar" :class="[stickyNav ? 'w-100 position-fixed start-0 top-0 bg-white z-to-150 shadow-sm' : 'position-absolute start-0 top-0 w-100 bg-primary z-to-150']">
    <div class="container-lg py-3">
      <div class="d-flex align-items-center justify-content-between">
        <div class="navbar-logo-img">
          <a class="navbar-brand" href="/">
            <img v-if="stickyNav" :src="require('../assets/images/logo_dark_door_shadow.png')" alt="logo" height="40">
            <img v-else :src="require('../assets/images/logo_light_door_shadow.png')" alt="logo" height="40">
          </a>
        </div>
        <div>
          <div class="d-none d-lg-block">
            <ul class="list-unstyled mb-0 d-flex align-items-center">
              <li v-for="link in navbar_links" :key="link.name" class="nav-item px-3">
                <a class="nav-link topnav-link text-black px-0 fw-bolder fs-16" :class="[link.url === currentUrl ? 'active' : '']" :href="link.url">{{link.name}}</a>
              </li>
              <li class="nav-item px-3">
                <a class="nav-link dropdown-toggle topnav-link text-black px-0 fw-bolder fs-16" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Properties
                </a>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="/listings">Listings</a></li>
                  <li><a class="dropdown-item" href="/property-for-rent">Rent</a></li>
                  <li><a class="dropdown-item" href="/property-for-sale">Sale</a></li>
                </ul>
              </li>
              <li v-if="this.current_user_email" class="nav-item px-2 d-flex align-items-center">
                <a :href="this.is_admin" class="text-dark text-decoration-none fw-bold fs-16 d-inline-block me-2">Admin</a>
                <a :href="this.edit_user_path" class="btn btn-dark rounded-pill d-inline-block me-2">{{this.current_user_email}}</a>
                <a :href="this.logout_path" rel="nofollow" data-method="delete" class="btn btn-dark rounded-pill d-inline-block">Logout</a>
              </li>
              <li v-else class="nav-item px-2 d-flex align-items-center">
                <a :href="this.login_path" class="btn btn-dark rounded-pill d-inline-block me-2">Login</a>
                <a :href="this.registration_path" class="btn btn-dark rounded-pill d-inline-block">Register</a>
              </li>
            </ul>
          </div>
          <div class="d-lg-none">
            <button
                class="btn bg-transparent border-0 no-shadow-btn p-0 fs-3 transition-2s square-25-menu d-flex align-items-center"
                @click="toggleSidebar"
            >
              <span class="d-block" v-if="sideMenuIsOpen"><i class="fa-solid fa-xmark"></i></span>
              <span class="d-block" v-else ><i class="fa-solid fa-bars"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
        class="position-fixed top-0 start-0 full-height bg-transparent transition-6s z-to-150"
        :class="[sideMenuIsOpen ? 'w-100' : 'w-0']"
    >
    <div
        class="position-absolute top-0 start-0 full-height bg-dark bg-opacity-25 transition-6s z-1"
        :class="[sideMenuIsOpen ? 'w-100' : 'w-0']"
        @click="toggleSidebar"
    ></div>
    <div
        class="full-height bg-white z-to-5"
        :class="[sideMenuIsOpen ? 'w-280-t-menu max-width-280' : 'w-0-t']"
    >
    <div :class="[!sideMenuIsOpen ? 'hide-element' : 'transition-4s']">
    <div class="d-flex align-items-center justify-content-center h-72 w-100 shadow-sm px-2 bg-primary">
      <a class="navbar-brand" href="/">
        <img :src="require('../assets/images/logo_light_door_shadow.png')" alt="logo" height="35">
      </a>
    </div>
    <ul class="list-unstyled px-0 pt-1">
      <li v-for="link in navbar_links" :key="link.name" class="border border-gray-300 border-start-0 border-end-0 border-top-0">
        <a class="sidebar-link w-100 d-inline-block text-decoration-none rounded-0 fs-18 text-dark py-2 px-3" :class="[link.url === currentUrl ? 'active' : '']" :href="link.url">{{link.name}}</a>
      </li>
      <li class="border border-gray-300 border-start-0 border-end-0 border-top-0">
        <a class="sidebar-link w-100 d-inline-block dropdown-toggle text-decoration-none text-dark py-2 px-3 fs-18" href="#" id="navbarDropdown-sidebar" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Properties
        </a>
        <ul class="dropdown-menu dropdown-menu-start w-280-t mx-2" aria-labelledby="navbarDropdown-sidebar">
          <li><a class="dropdown-item" href="/listings">Listings</a></li>
          <li><a class="dropdown-item" href="/property-for-rent">Rent</a></li>
          <li><a class="dropdown-item" href="/property-for-sale">Sale</a></li>
        </ul>
      </li>
      <li v-if="this.current_user_email" class="nav-item py-2 px-3">
        <a :href="this.is_admin" class="btn btn-primary rounded-pill d-block mb-2">Admin</a>
        <a :href="this.edit_user_path" class="btn btn-primary rounded-pill d-block mb-2">{{this.current_user_email}}</a>
        <a :href="this.logout_path" rel="nofollow" data-method="delete" class="btn btn-primary rounded-pill d-block">Logout</a>
      </li>
      <li v-else class="nav-item py-2 px-3">
        <a :href="this.login_path" class="btn btn-primary rounded-pill d-block mb-2">Login</a>
        <a :href="this.registration_path" class="btn btn-primary rounded-pill d-block">Register</a>
      </li>
    </ul>
    </div>
    </div>
    </div>
  </nav>
</template>
<script>
export default {
  props: ['current_user', 'is_admin', 'current_user_email', 'edit_user_path', 'login_path', 'logout_path', 'registration_path'],
  data() {
    return {
      stickyNav: false,
      sideMenuIsOpen: false,
      currentUrl: window.location.pathname,
      navbar_links: [
        {
          name: 'Home',
          url: '/'
        },
        {
          name: 'Rent',
          url: '/houses-for-rent'
        },
        {
          name: 'Buy',
          url: '/houses-for-sell'
        },
        {
          name: 'Short stay',
          url: '/short-stay'
        }
      ],
      navbar_sub_links: [
        {
          name: 'About us',
          url: '/about-us'
        },
        {
          name: 'Contact',
          url: '/contact'
        }
      ]
    };
  },
  methods: {
    handleScroll(event){
      let elementBounds = document.getElementById('navbar-placeholder');
      if(elementBounds){
        let { top } = elementBounds.getBoundingClientRect();
        if (top < -120) {
          this.stickyNav = true;
        } else {
          this.stickyNav = false;
        }
      }
    },
    toggleSidebar(){
      this.sideMenuIsOpen = !this.sideMenuIsOpen;
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
