<template>
  <header class="w-100 min-height-60vh position-relative bg-dark bg-opacity-75 d-flex align-items-center justify-content-center">
    <div class="position-absolute start-0 top-0 w-100 h-100 z-1">
      <picture>
        <source media="(max-width: 360px)" :srcset="require('../assets/images/home_for_sale_xs.jpg')">
        <source media="(max-width: 679px)" :srcset="require('../assets/images/home_for_sale_md.jpg')">
        <source media="(min-width: 679px)" :srcset="require('../assets/images/home_for_sale_lg.jpg')">
        <img height="150" width="150" :src="require('../assets/images/home_for_sale_lg.jpg')" alt="City" class="cover-img-bottom" />
      </picture>
    </div>
    <div class="max-width-80p px-2 my-5">
      <h1 class="fs-38 ls-6 text-light text-center"><span class="d-block">Easy way to</span> <span class="d-block">Rent, Sell, Buy Properties Online</span></h1>
      <div class="text-center">
        <a href="/new-advertisement" class="btn btn-light rounded-0 my-3 text-uppercase ls-8">New Listing</a>
      </div>
      <p class="text-center text-white mt-3">Find Apartments and Homes For Rent, For Holiday(Short Stay), For Sale and Plots.</p>
      <div class="d-flex flex-column flex-sm-row align-items-center justify-content-around">
        <a href="/property-for-rent#apt-cards" class="btn rounded-0 bg-light text-dark text-uppercase mb-2 btn-min-width">For Rent</a>
        <a href="/property-for-sale#apt-cards" class="btn rounded-0 bg-light text-dark text-uppercase mb-2 btn-min-width">For Sale</a>
        <a href="/fully-furnished-apartments#apt-cards" class="btn rounded-0 bg-light text-dark text-uppercase mb-2 btn-min-width">Short stay</a>
        <a href="/plots-for-sale" class="btn rounded-0 bg-light text-dark text-uppercase mb-2 btn-min-width">Plots</a>
      </div>
      <div class="d-flex flex-column flex-md-row mt-4">
        <select v-model="period" class="h-46-px fs-16 border-0 bg-primary px-3 mb-2 outline-none ">
          <option value="rent">For Rent</option>
          <option value="sell">For Sell</option>
          <option value="holiday">Short Stay</option>
          <option value="plots">Plots</option>
        </select>
        <div class="flex-grow-1 position-relative z-to-5">
          <input type="text" placeholder="Nairobi" class="h-46-px border-0 fs-16 px-3 mb-2 w-100 form-control rounded-0" ref="cityInput" v-on:keydown="showCities($event)" v-model="currentCity" autocomplete="false" />
          <ul v-if="showDropdown" class="list-unstyled position-absolute top-100 start-0 w-100 overflow-auto bg-light" style="max-height: 150px;">
            <li v-for="city in selectableCities" v-on:click="updateSelected(city)" :key="city" class="px-2 py-1 border-bottom cursor-pointer hero-city-list">{{ city }}</li>
          </ul>
          <button v-if="showDropdown" @click="toggleDropdown" class="position-absolute square-15 bg-light border-0 rounded-circle no-shadow-btn p-0 d-flex align-items-center justify-content-center" style="top: 46px; right: -8px;">
            <i class="fa-solid fa-x text-dark fs-10"></i>
          </button>
        </div>
        <a @click="proceedRequest" class="h-46-px fs-18 px-4 mb-2 btn btn-primary rounded-0 d-flex align-items-center justify-content-center">
          <span class="d-block">
            <i class="fa-solid fa-magnifying-glass"></i>
          </span>
          <span class="d-block ms-2">Search</span>
        </a>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  props: ['cities', 'holiday', 'rent', 'sell'],
  data() {
    return {
      currentCity: "",
      selectedCity: "",
      selectableCities: [],
      showDropdown: false,
      period: 'rent',
      targetRoute: {
        holiday: "/",
        rent: "/",
        sell: "/",
        plots: "/plots"
      }
    };
  },
  methods: {
    showCities(e){
      let filteredCities = this.cities.filter(city => city.toLowerCase().startsWith(e.target.value.toLowerCase()))
      this.selectableCities = filteredCities;
      if(this.selectableCities.length > 0 && !this.showDropdown){
        this.showDropdown = true
      }

      if(e.target.value.length === 0) {
        this.showDropdown = false;
      }
    },
    updateSelected(city){
      this.$refs.cityInput.value = city;
      this.currentCity = city
      this.showDropdown = false;
    },
    toggleDropdown(){
      this.showDropdown = false;
    },
    proceedRequest(){
      let url = this.targetRoute[this.period];
      if(this.period && this.currentCity){
        url += `?city=${this.currentCity}#apt-cards`
      }else if(this.period){
        url += `#apt-cards`
      }
      window.location.href = url;
    },
    scrollToSection(){
      document.getElementById('how-it-works-section').scrollIntoView()
    }
  },
  created() {
    if(this.cities){
      this.selectableCities = this.cities;
    }
    'holiday', 'rent', 'sell'
    if(this.holiday){
      this.targetRoute['holiday'] = this.holiday;
    }

    if(this.rent){
      this.targetRoute['rent'] = this.rent;
    }

    if(this.sell){
      this.targetRoute['sell'] = this.sell;
    }
  }
};
</script>