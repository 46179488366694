<template>
  <header>
    <div
      id="carouselHeroCaptions"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div v-if="show_extra_info" class="carousel-indicators">
        <button
          v-for="(size, index) in heroContentSize"
          :key="size"
          type="button"
          data-bs-target="#carouselHeroCaptions"
          :data-bs-slide-to="index"
          class="active"
          aria-current="true"
          :aria-label="'Slide ' + (index + 1)"
        ></button>
      </div>
      <div class="carousel-inner">
        <div v-for="(item, index) in heroContent" :key="index" :class="['carousel-item', index === 0 ? 'active': '']">
          <div class="w-100" :style="{maxHeight: heroHeight + 'vh'}">
            <picture>
              <source media="(max-width: 360px)" :srcset="require(`../assets/images/${item.image}_sm.jpeg`)">
              <source media="(max-width: 679px)" :srcset="require(`../assets/images/${item.image}_md.jpeg`)">
              <source media="(min-width: 679px)" :srcset="require(`../assets/images/${item.image}_lg.jpeg`)">
              <img
                  :src="require(`../assets/images/${item.image}_md.jpeg`)"
                  class="cover-img"
                  :alt="item.main_heading"
              />
            </picture>
          </div>
          <div v-if="show_extra_info" class="hero-heading-size carousel-caption bottom-50 bg-dark-transparent-35 rounded-3 translate-y-to-50">
            <h5>{{item.main_heading}}</h5>
            <p>
              {{item.description}}
            </p>
            <a :href="item.direct_to" class="btn btn-light rounded-0 py-2">Reserve now</a>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselHeroCaptions"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselHeroCaptions"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </header>
</template>
<script>
export default {
  props: ['data', 'carousel_height', 'show_extra_info'],
  data() {
    return {
      heroContent: [],
      heroContentSize: 0,
      heroHeight: 90,
      extraInfo: false
    };
  },
  methods: {
  },
  created() {
    if(this.data){
      this.heroContentSize = this.data.length;
      this.heroContent = this.data;
    }

    if(this.carousel_height){
      this.heroHeight = this.carousel_height;
    }

    if(this.show_extra_info){
      this.extraInfo = this.show_extra_info;
    }
  }
};
</script>
