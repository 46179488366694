<template>
  <div class="position-relative max-height-350">
    <label class="form-label fs-16" :for="this.input_ids[0]">{{this.label_name || 'City'}}</label>
    <input ref="cityInputSelectable" :list="this.input_ids[1] + '_city'" type="text" :id="this.input_ids[0]" class="form-control rounded-0 py-2" v-on:keyup="showCities($event)" :placeholder="this.placeholder" />
    <input type="hidden" :id="this.input_ids[1]" :name="this.input_ids[1]" :value="currentCity" />
    <ul v-if="showDropdown" class="list-unstyled position-absolute top-100 start-0 w-100 overflow-auto bg-primary rounded-3 z-to-5" style="max-height: 250px;">
      <li v-for="city in selectableCities" v-on:click="updateSelected(city)" :key="city" class="px-2 py-1 border-bottom cursor-pointer">{{ city }}</li>
    </ul>
    <button v-if="showDropdown" @click="toggleDropdown" class="btn btn-light square-25 position-absolute end-0 top-100 z-to-5 border-1 border-dark rounded-circle p-2 d-flex align-items-center justify-content-center text-white" style="margin-right: -10px;">
      <i class="fa-solid fa-x text-dark fs-12"></i>
    </button>
  </div>
</template>
<script>
export default {
  props: ['cities', 'active_city', 'label_name', 'placeholder', 'input_ids'],
  data() {
    return {
      currentCity: "",
      selectedCity: "",
      selectableCities: [],
      showDropdown: false
    };
  },
  methods: {
    showCities(e){
      let filteredCities = this.cities.filter(city => city.toLowerCase().startsWith(e.target.value.toLowerCase()))
      this.selectableCities = filteredCities;
      if(this.selectableCities.length > 0 && !this.showDropdown){
        this.showDropdown = true
      }

      if(e.target.value.length === 0){
        this.showDropdown = false;
      }
    },
    updateSelected(city){
      this.$refs.cityInputSelectable.value = city;
      this.currentCity = city
      this.showDropdown = false;
    },
    toggleDropdown() {
      this.showDropdown = false;
    }
  },
  created() {
    if(this.cities){
      this.selectableCities = this.cities;
    }
    if(this.active_city){
      this.currentCity = this.active_city;
    }
  }
};
</script>