// https://codepen.io/zebresel/pen/xGLYOM
// https://dev.to/mandrewcito/vuejs-double-range-slider-component-2c5a

const csrfToken = document.querySelector("meta[name=csrf-token]").content
window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-CSRF-Token"] = csrfToken

import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue/dist/vue.esm";

Vue.config.ignoredElements = ['amp-ad'];

import Navbar from "../components/Navbar.vue";
import AppFooter from "../components/AppFooter.vue";
import CardsContainer from "../components/CardsContainer"
// Pages
import FrontPage from "../pages/FrontPage.vue";
import SingleItem from "../pages/SingleItem";
import CarouselHero from "../components/CarouselHero.vue";
import AppSelectable from "../components/AppSelectable";
import Hero from "../components/Hero";
import Listing from "../components/Listing";
import ContentActions from "../components/ContentActions";
import SearchAgentInfo from "../components/SearchAgentInfo";
import HowWorks from "../components/HowWorks";
import Pricing from "../components/Pricing";
import Sorter from "../components/Sorter";
import SubscriptionForm from "../components/SubscriptionForm";
import SearchAlert from "../components/SearchAlert";
import Facility from "../components/Facility.vue";
import SubscriptionFormManual from "../components/SubscriptionFormManual";
import ContactInfo from "../components/ContactInfo";
import CookieNotification from "../components/CookieNotification";
import PhotoPreviewer from "../components/PhotoPreviewer";
import FeaturedPropertyCard from "../components/FeaturedPropertyCard";

Vue.use(TurbolinksAdapter);

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

document.addEventListener("turbolinks:load", () => {
  const app = new Vue({
    el: "#app", //el: "[data-behavior='vue']"
    data: () => {
      return {
        message: "Can you say hello?",
      };
    },
    components: {
      Navbar,
      AppFooter,
      FrontPage,
      CardsContainer,
      SingleItem,
      CarouselHero,
      AppSelectable,
      Hero,
      Listing,
      ContentActions,
      SearchAgentInfo,
      HowWorks,
      Pricing,
      Sorter,
      SubscriptionForm,
      SearchAlert,
      Facility,
      SubscriptionFormManual,
      ContactInfo,
      CookieNotification,
      PhotoPreviewer,
      FeaturedPropertyCard
    },
  });
});
