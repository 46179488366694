<template>
  <section class="container">
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
      <div class="col">
        <div class="w-100 h-100 text-center p-2 p-sm-3">
          <div class="mx-auto mb-3 fs-54 text-info">
            <i class="fa-solid fa-house-circle-check"></i>
          </div>
          <h3 class="fs-3 mb-3">Looking for home</h3>
          <p>Find a new home or check out homes that are for sale or rent homes on the market.</p>
          <a :href="this.sell" class="btn btn-outline-dark border-2 rounded-0 ls-6 mb-3 text-uppercase">Homes for sale</a>
          <a :href="this.rent" class="btn btn-outline-dark border-2 rounded-0 ls-6 mb-3 text-uppercase">Homes for rent</a>
        </div>
      </div>
      <div class="col">
        <div class="w-100 h-100 text-center p-2 p-sm-3">
          <div class="mx-auto mb-3 fs-54 text-info">
            <i class="fa-solid fa-house-chimney-window"></i>
          </div>
          <h3 class="fs-3 mb-3">Selling a house</h3>
          <p>Whether you are selling an apartment yourself or through a broker - we will help you succeed.</p>
          <a :href="this.sell" class="btn btn-outline-dark border-2 rounded-0 ls-6 mb-3 text-uppercase">Start selling</a>
        </div>
      </div>
      <div class="col">
        <div class="w-100 h-100 text-center p-2 p-sm-3">
          <div class="mx-auto mb-3 fs-54 text-info">
            <i class="fa-solid fa-house-laptop"></i>
          </div>
          <h3 class="fs-3 mb-3">Renting an apartment</h3>
          <p>Make a free rental announcement and find a new tenant quickly and easily with our help.</p>
          <a :href="this.rent" class="btn btn-outline-dark border-2 rounded-0 ls-6 mb-3 text-uppercase">Start renting</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ["holiday", "sell", "rent"]
}
</script>