<template>
  <div class="card h-100 shadow" :id="apartment.id">
    <div class="height-240 position-relative">
      <div :id="'carouselCardControls'+apartment.id" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
        <div class="carousel-inner">
          <div class="carousel-item active height-240">
            <img src="https://images.unsplash.com/photo-1618219740975-d40978bb7378?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1249&q=80" class="cover-img rounded-top" alt="...">
          </div>
          <div class="carousel-item height-240">
            <img src="https://images.unsplash.com/photo-1611892440504-42a792e24d32?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" class="cover-img rounded-top" alt="...">
          </div>
          <div class="carousel-item height-240">
            <img src="https://images.unsplash.com/photo-1571508601891-ca5e7a713859?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80" class="cover-img rounded-top" alt="...">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselCardControls'+apartment.id" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" :data-bs-target="'#carouselCardControls'+apartment.id" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <button class="position-absolute square-35 btn bg-light border-0 rounded-circle d-flex align-items-center justify-content-center p-2" style="left: 16px; top: 16px;">
        <span class="d-block"><i class="fa-solid fa-heart"></i></span>
      </button>
      <button class="position-absolute btn btn-info py-2 px-3 rounded-pill m-0 text-uppercase fs-14 text-light translate-y-to-50 border-0 no-shadow-btn shadow-sm" style="bottom: 0; left: 16px">
        {{apartment.badge}}
      </button>
    </div>
    <div class="card-body">
      <h3 class="fs-14 fw-light text-black-50 d-flex align-items-center mt-3 mb-2">
            <span class="square-15 d-block me-2">
              <i class="fa-solid fa-location-dot"></i>
            </span>
        <span class="d-block mt-1">{{apartment.city}}</span>
      </h3>
      <h4 class="fs-22 fw-normal ls-6 mb-3">{{apartment.apartment_name}}</h4>
      <ul class="list-unstyled">
        <li class="d-flex align-items-center mb-2">
              <span class="d-block square-20 me-2">
                <i class="fa-solid fa-bed-front"></i>
              </span>
          <p class="mb-0 mt-1">{{apartment.bedroom}} Bedrooms</p>
        </li>
        <li class="d-flex align-items-center mb-2">
              <span class="d-block square-20 me-2">
                <i class="fa-solid fa-user"></i>
              </span>
          <p class="mb-0 mt-1">{{apartment.guest}} Guests</p>
        </li>
        <li class="d-flex align-items-center mb-2">
              <span class="d-block square-20 me-2">
                <i class="fa-solid fa-ruler-combined"></i>
              </span>
          <p class="mb-0 mt-1">Area {{apartment.area}}m<sup>2</sup></p>
        </li>
      </ul>
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h5 class="mb-0 text-black">
            <span class="fs-30">{{apartment.price}}</span>
            <span class="fs-14 text-capitalize">Kshs / {{apartment.price_duration}}</span>
          </h5>
        </div>
        <a :href="getFullPath(apartment.id, apartment.slug)" class="btn btn-primary rounded-circle ls-6 fs-16 square-50 d-flex align-items-center justify-content-center shadow-sm">
          <i class="fa-solid fa-chevron-right"></i>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['apartment'],
  methods: {
    getFullPath(id, url){
      let path = location.href
      localStorage.setItem("last-scrolled-to-item", id);
      return `/apartments/${url}`;
    }
  }
}
</script>