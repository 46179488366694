<template>
  <div>
    <div v-if="is_sent">
      <p>{{message}}</p>
    </div>
    <div v-else>
      <p v-if="this.has_result > 0" class="text-danger">Your search has already found {{this.has_result}} items. Use the filter to set specific values. Example. Price: 1000 min (minimum price) or 5000 max (maximum price) and so on.</p>
      <p class="mb-2">Give your Search Alert a name.</p>
      <div class="form-floating mb-3">
        <input type="text" v-model="search_agents.search_name" @keydown="removeError" required :class="not_validated ? 'form-control is-invalid' : 'form-control'" id="search_name" placeholder="Search Name">
        <label for="search_name">Search Name</label>
      </div>
      <div class="d-grid">
        <button @click="submit" class="btn btn-primary btn-lg rounded-0">Save</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  props: ["category", "user", "has_result"],
  data(){
    return {
      search_agents: {
        search_name: "",
        link: location.href,
        location: location.href,
        category: "",
      },
      is_sent: false,
      not_validated: false,
      message: ""
    }
  },
  methods: {
    submit(){
      if(this.search_agents.search_name){
        axios.post('/search_agents', this.search_agents)
        .then(response => {
          const { status } = response.data;
          if(status === 'created'){
            this.message = "Your Search Alert has been saved successfully."
            this.is_sent = true;
          }else if(status === 'unprocessable_entity'){
            this.message = "Error saving Search Alert. Please try again later"
          }
        }).catch(error => console.log(error))
      }else {
        this.not_validated = true;
      }
    },
    removeError(){
      if(this.not_validated){
        this.not_validated = false;
      }
    }
  },
  created() {
    if(this.url){
      this.search_agents.link = this.url;
    }

    if(this.category){
      this.search_agents.category = this.category;
    }
  }
}
</script>