<template>
  <section v-if="isNotified"  class="position-fixed start-0 bottom-0 w-100 m-0 bg-primary py-3 py-sm-5 z-to-6">
    <div class="container">
      <p>This website uses cookies to improve your experience. By using this site you agree to the storing of cookies on your device to enhance navigation, analyze site usage and assist in our marketing efforts. To learn more, <a href="/privacy" class="text-dark">see our cookie policy</a>.</p>
      <button @click="closeDialog" class="btn btn-dark btn-lg rounded-0 text-white">Accept and close</button>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      isNotified: false,
    };
  },
  methods: {
    setCookie(cname, cvalue, exdays) {
      let d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          //return c.substring(name.length, c.length);
          return true;
        }
      }
      //return "";
      return false;
    },
    closeDialog(){
      this.isNotified = false;
      this.setCookie("cookie-info", "2555833780632468", 7);
    },
    checkCookie() {
      var user = this.getCookie("cookie-info");
      if (user) {
        this.isNotified = false;
        this.setCookie("cookie-info", "2555833780632468", 7);
      }else {
        this.isNotified = true
      }
    },
  },
  created() {
    this.checkCookie();
  },
};
</script>