<template>
  <section class="bg-primary bg-opacity-25 py-5 my-4">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="w-100 h-100 d-flex align-items-center justify-content-center">
            <div class="fs-70 text-primary mb-5 mb-md-0">
              <i class="fa-solid fa-bell"></i>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="w-100">
            <h4 class="fs-1 mb-4">Search Alert - Don't miss the house of your dream</h4>
            <p>Be the first to know about new homes. You will be among the first to be notified when new homes that match your search are announced. This will ensure that you do not miss any announcement of new homes.</p>
            <h5 class="fs-5">With the help of Search Alert you will be</h5>
            <ul>
              <li>Among the first to be notified about new announcements</li>
              <li>Notified if the price of a house changes</li>
              <li>Informed about upcoming new constructions</li>
            </ul>
            <div class="pt-4">
              <a :href="this.registration" class="btn btn-outline-dark rounded-0 border-2 text-uppercase">Create Search alert</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ["registration"]
}
</script>