<template>
  <section class="py-5">
    <div class="container">
      <h2 class="fs-42 ls-6 mb-4 text-center">Featured Apartments</h2>
      <div class="w-100 position-relative">
        <button v-if="showRightArrow" @click="scrollHorizontally('right')" class="listing-next position-absolute top-50 rounded-circle border-0 shadow-sm d-flex align-items-center justify-content-center p-2 bg-light z-to-5">
          <span class="d-block">
            <i class="fa-solid fa-chevron-right"></i>
          </span>
        </button>
        <button v-if="showLeftArrow" @click="scrollHorizontally('left')" class="position-absolute top-50 rounded-circle border-0 shadow d-flex align-items-center justify-content-center p-2 bg-light z-to-5 listing-previous">
          <span class="d-block">
            <i class="fa-solid fa-chevron-left"></i>
          </span>
        </button>
        <div class="w-100 overflow-auto d-flex list_container height-200" ref="list_container" style="">
          <div v-for="(apartment, index) in apartments" :key="`${apartment.id}${index}`" class="list_container_box position-relative">
            <a v-if="apartment.image" :href="'/' + getApartmentPath(apartment.category, apartment.slug)" class="text-decoration-none text-dark">
              <picture v-if="apartment.image">
                <source media="(max-width: 650px)" :srcset="apartment.image_small" :alt="apartment.city">
                <img :src="apartment.image" :alt="apartment.city" class="cover-img" loading="lazy">
              </picture>
              <div v-if="apartment.price" class="w-100 position-absolute start-0 bottom-0 p-2 bg-dark bg-opacity-75 text-white">
                <h3 class="fs-5 mb-1">{{formatPrice(apartment.price)}} Kshs</h3>
                <p class="mb-0">{{apartment.location}}, {{apartment.city}}</p>
              </div>
            </a>
            <div v-else class="w-280 height-200 bg-dark bg-opacity-25 d-flex align-items-center justify-content-center" style="border: 1px dashed #ddd;">
              <p class="fs-12 mb-0 text-center">Advertise here</p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center my-5">
        <a href="/houses-for-sell" class="btn btn-lg btn-outline-primary border-2 rounded-0 text-uppercase">View all apartments</a>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    props: ["featured", "photos"],
    data(){
      return {
        listings: [],
        showRightArrow: false,
        showLeftArrow: true,
        apartments: [
          {
            id: 1,
            image: "",
            location: "",
            city: "",
            price: "",
            slug: ""
          },
          {
            id: 2,
            image: "",
            location: "",
            city: "",
            price: "",
            slug: ""
          },
          {
            id: 3,
            image: "",
            location: "",
            city: "",
            price: "",
            slug: ""
          },
          {
            id: 4,
            image: "",
            location: "",
            city: "",
            price: "",
            slug: ""
          },
          {
            id: 5,
            image: "",
            location: "",
            city: "",
            price: "",
            slug: ""
          }
        ]
      }
    },
    methods: {
      scrollHorizontally(side){
        let mainContainer = this.$refs.list_container;
        let { width: viewportWidth } = this.calculatedViewport();
        let boxes = mainContainer.querySelectorAll('.list_container_box')
        let boxesSize = boxes.length;
        let firstBox = boxes[0]
        let lastBox = boxes[boxesSize - 1]
        let { x: firstChildX } = firstBox.getBoundingClientRect();
        let { x: lastChildX } = lastBox.getBoundingClientRect();
        let sideDifference = (viewportWidth - mainContainer.clientWidth) / 2;
        mainContainer.style.scrollBehavior = 'smooth';
        if(side === 'left'){
          mainContainer.scrollLeft += 280;
          if(firstChildX - sideDifference < 0){
          // Show right arrow
            this.showRightArrow = true;
            this.showLeftArrow = lastChildX >= mainContainer.clientWidth;
          }
        }

        if(side === 'right'){
          mainContainer.scrollLeft -= 280;
          this.showLeftArrow = mainContainer.clientWidth - boxesSize * 280 <= 0;

          if(firstChildX > 0){
            this.showRightArrow = false;
          }
        }
      },
      calculatedViewport() {
        let e = window,
            a = "inner";
        if (!("innerWidth" in window)) {
          a = "client";
          e = document.documentElement || document.body;
        }
        return { width: e[a + "Width"], height: e[a + "Height"] };
      },
      formatPrice(price){
        return (price * 1).toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 });
      },
      getApartmentPath(category, id){
        let cat = category || [];
        if(cat.indexOf('sell') >= 0){
          return `houses-for-sell/${id}`;
        }else if(cat.indexOf('rent') >= 0){
          return `houses-for-rent/${id}`;
        } else if(cat.indexOf('holiday') >= 0){
          return `short-stay/${id}`;
        }else {
          return ''
        }
      }
    },
    created() {
      if(this.featured && this.featured.length > 0){
        if(this.featured.length <= 5){
          this.apartments = [...this.featured, ...this.apartments];
        }else {
          this.apartments = this.featured
        }

        this.apartments = this.apartments.map(ap => {
          let photo = this.photos[ap.id]
          if(photo){
            return {...ap, image: photo["large"], image_small: photo["small"]}
          }
          return {...ap, image: "", image_small: ""}
        })
      }
    }
  }
</script>