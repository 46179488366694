<template>
  <div>
    <carousel-hero :data="carousel_data" :carousel_height="carousel_height"></carousel-hero>
  </div>
</template>
<script>
import CarouselHero from "../components/CarouselHero.vue";
export default {
  props: ['carousel_datass'],
  data(){
    return {
      carousel_height: 90,
      carousel_data: [
        {
          id: 1,
          image: 'https://images.unsplash.com/photo-1612320648993-61c1cd604b71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1052&q=80',
          main_heading: 'Awesome Beachfront',
          description: 'The best apartment in town. Easily accessible.',
          direct_to: '/'
        },
        {
          id: 2,
          image: 'https://images.unsplash.com/photo-1484154218962-a197022b5858?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80',
          main_heading: 'Hidaya Beachfront',
          description: 'Family holiday apartments with affordable price.',
          direct_to: '/'
        },
        {
          id: 3,
          image: 'https://images.unsplash.com/photo-1539922631499-09155cc609a4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80',
          main_heading: 'All One apartments',
          description: 'Enjoy your holiday with our apartment.',
          direct_to: '/'
        }
      ]
    }
  },
  components: {
    CarouselHero,
  },
};
</script>
