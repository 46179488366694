<template>
  <div class="featured-card w-100 h-100 min-height-300px border border-gray-300 rounded-5 overflow-hidden d-flex flex-column flex-sm-row shadow">
    <div class="featured-card__img h-100">
      <a :href="`/listings/${apartment.slug}`">
        <div class="w-100 h-100 position-relative">
          <img v-if="photo" :src="photo" :alt="apartment.title" class="cover-img" loading="lazy"/>
          <div v-else class="w-100 h-100 d-flex align-items-center justify-content-center">
            <span class="d-block fs-44">
              <i class="fa-solid fa-house"></i>
            </span>
          </div>
          <div class="position-absolute start-0 top-0 w-100 d-flex justify-content-between">
            <button v-if="apartment.is_featured" class="btn btn-primary btn-sm m-3 text-light fs-14">
              Featured
            </button>
            <button v-if="apartment.category === 'sale'" class="btn btn-sm bg-dark bg-opacity-50 m-3 text-light fs-14">
              For Sale
            </button>
            <button v-if="apartment.category === 'rent'" class="btn btn-sm bg-dark bg-opacity-50 m-3 text-light fs-14">
              For Rent
            </button>
          </div>
        </div>
      </a>
    </div>
    <div class="featured-card__text position-relative h-100 p-3 p-md-4 bg-white flex-1">
      <a :href="`/listings/${apartment.slug}`" class="text-decoration-none text-dark">
        <div class="w-100">
          <h3 class="fs-16 fw-bold text-uppercase ls-6 mb-2">
            {{apartment.content_title}}
          </h3>
          <p class="d-flex text-gray-600 mb-3">
                <span class="d-block me-2">
                  <i class="fa-solid fa-location-dot"></i>
                </span>
            <span class="d-block fs-14 ls-6">
                  <span>{{apartment.full_address}}</span>
                </span>
          </p>
          <div class="mw-lg-80 d-flex justify-content-between flex-wrap">
            <div v-for="(feature, index) in JSON.parse(apartment.features)" :key="index" class="w-50" style="min-width: 180px">
              <p class="d-flex fs-14 ls-6">
                    <span v-if="activeIcon(feature, 'bed')" class="d-block me-2">
                      <i class="fa-solid fa-bed"></i>
                    </span>
                  <span v-if="activeIcon(feature, 'bath')" class="d-block me-2">
                      <i class="fa-solid fa-shower"></i>
                    </span>
                  <span v-if="activeIcon(feature, 'area')" class="d-block me-2">
                      <i class="fa-solid fa-ruler-combined"></i>
                  </span>
                <span v-if="activeIcon(feature, 'toilet')" class="d-block me-2">
                      <i class="fa-solid fa-toilet"></i>
                  </span>
                <span v-if="activeIcon(feature, 'park')" class="d-block me-2">
                      <i class="fa-solid fa-warehouse"></i>
                  </span>
                <span v-if="feature !== '&nbsp;'" class="d-block">{{feature}}</span>
              </p>
            </div>
          </div>
        </div>
      </a>
      <div class="w-100 h-40"></div>
      <div class="position-absolute start-0 bottom-0 w-100">
        <div class="w-100 p-3 p-md-4">
          <hr class="bg-gray-500"/>
          <div class="d-flex align-item-center justify-content-between">
            <p class="mb-0 fw-bold ls-6">{{apartment.price_data}}</p>
            <p class="mb-0 d-flex">
                <span class="d-block cursor-pointer me-4">
                  <i class="bi bi-share"></i>
                </span>
              <span
                  @click="toggleLiked"
                  class="d-block cursor-pointer"
              >
            <i v-if="liked" class="fa-solid fa-heart text-danger"></i>
            <i v-else class="fa-solid fa-heart"></i>
          </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['apartment', 'photo'],
  data() {
    return {
      liked: false,
    }
  },
  methods: {
    toggleLiked() {
      this.liked = !this.liked;
    },
    isRent(){
      return this.apartment.category.indexOf('rent') >= 0 ? 'For Rent' : this.apartment.category.indexOf('sell') >= 0 ? 'For Sale' : ""
    },
    activeIcon(feature, str){
      let feat = feature.toLowerCase().split(" ")
      let isFound = false;
      feat.map(f => {
        if(f.startsWith(str)){
          isFound = true
        }
      })

      return isFound
    },
    formatPrice(price) {
      return (price * 1).toLocaleString(undefined, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }
  }
}
</script>