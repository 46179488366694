<template>
  <div>
    <a class="text-dark" v-if="showContact" :href="`tel:${contact}`">{{contact}}</a>
    <span v-else>{{hidePhoneNumber(contact)}}</span>
    <a v-if="!showContact" @click="toggleContactInfo" class="btn btn-outline-primary mx-3 btn-sm p-1"> Show </a>
  </div>
</template>
<script>
export default {
  props: ["name", "contact"],
  data(){
    return {
      showContact: false
    }
  },
  methods: {
    toggleContactInfo(){
      this.showContact = !this.showContact;
    },
    hidePhoneNumber(phone){
      let l = phone.length;
      let first = phone.substring(0, 4)
      let diff = l - first.length
      let i = 0;
      let xx = ""
      while(i < diff){
        xx += "X";
        i++;
      }
      return `${first}${xx}`;
    }
  }
}
</script>