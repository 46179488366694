<template>
  <div>
    <carousel-hero :data="carousel_data" :carousel_height="carousel_height" :show_extra_info="false"></carousel-hero>
    <main>
      <div class="container">
        <div class="max-width-960 mx-auto">
          <div class="max-width-680 pt-5 ">
            <h2 class="mb-3">
              <span class="fs-48 text-primary ls-6">{{apartment.price}}</span>
              <span class="h6 ls-6 text-primary text-capitalize"> Kshs / {{apartment.price_duration}}</span>
            </h2>
            <h1 class="fs-1 ls-6">{{ apartment.apartment_name }}, {{apartment.city}}</h1>
            <p>{{apartment.summary}}</p>
          </div>
        </div>
        <div class="pt-3">
          <div class="max-width-960 mx-auto">
            <h2 class="fs-2">Facilities</h2>
            <Facility :apartment="apartment" />
          </div>
        </div>
        <div class="py-3" v-if="apartment.description">
          <div class="max-width-960 mx-auto">
            <h2 class="fs-2">Description</h2>
            <p>{{apartment.description}}</p>
          </div>
        </div>
        <div class="py-3" v-if="distances">
          <div class="max-width-960 mx-auto">
            <h2 class="fs-2">Distances (KM)</h2>
            <p>The distances in KM are approximate and are provided by the owner of the announcement. You may contact the owner for more information.</p>
            <div class="row">
              <div v-for="(value, name) in distances" v-if="value !== '0'" class="col-sm-6 col-md-3">
                <p class="mb-0 mt-1 fs-16 text-capitalize">{{ parseDistancesKey(name) }}:</p>
                <p class="mb-0 mt-1 fs-16">{{ value }}</p>
              </div>
            </div>
          </div>
          <div class="max-width-960 mx-auto my-5">
            <h2 class="fs-2">Contact</h2>
            <div class="mt-3">
              <h3 class="fs-5">{{apartment.contact_name}}</h3>
              <h4 class="fs-5">Tel: <a :href="'tel:' + apartment.contact_number">{{apartment.contact_number}}</a></h4>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import CarouselHero from "../components/CarouselHero.vue";
import Facility from "../components/Facility.vue";
export default {
  props: ['apartment', 'carousel_datass'],
  data(){
    return {
      carousel_height: 80,
      carousel_data: [
        {
          id: 1,
          image: 'https://images.unsplash.com/photo-1534655610770-dd69616f05ff?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=866&q=80',
          main_heading: 'Awesome Beachfront',
          description: 'The best apartment in town. Easily accessible.',
          direct_to: '/'
        },
        {
          id: 2,
          image: 'https://images.unsplash.com/photo-1617104678098-de229db51175?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1088&q=80',
          main_heading: 'Hidaya Beachfront',
          description: 'Family holiday apartments with affordable price.',
          direct_to: '/'
        },
        {
          id: 3,
          image: 'https://images.unsplash.com/photo-1539922631499-09155cc609a4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80',
          main_heading: 'All One apartments',
          description: 'Enjoy your holiday with our apartment.',
          direct_to: '/'
        },
        {
          id: 4,
          image: 'https://images.unsplash.com/photo-1618296778887-7924838508a2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
          main_heading: 'Xenia apartments',
          description: 'Enjoy your holiday with our apartment at the heart of the city center',
          direct_to: '/'
        },
        {
          id: 5,
          image: 'https://images.unsplash.com/photo-1617099443741-a9b51eabd2b8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE4fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
          main_heading: 'All One apartments',
          description: 'Enjoy your holiday with our apartment.',
          direct_to: '/'
        }
      ],
      facilities: [],
      distances: null
    }
  },
  methods: {
    parseDistancesKey(key){
      return key.split("_").join(" ");
    }
  },
  created() {
    if(this.apartment){
      this.facilities = [
        {name: '', val: this.apartment}
      ]
      this.distances = this.apartment.distances;
      if(typeof this.distances === 'string'){
        this.distances = JSON.parse(this.distances)
      }
    }
  },
  components: {
    CarouselHero,
    Facility
  },
};
</script>