<template>
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
    <div class="col" v-for="apartment in apartments" :key="apartment.id">
      <Card :apartment="apartment" />
    </div>
  </div>
</template>
<script>
import Card from './Card'
export default {
  props: ['data', 'heading'],
  data() {
    return {
      apartments: [],
      mainHeading: ''
    };
  },
  methods: {
  },
  components: {
    Card
  },
  created() {
    if(this.data){
      this.apartments = this.data;
      this.mainHeading = this.heading;
    }
    let id = localStorage.getItem("last-scrolled-to-item");
    let element = document.getElementById(id)
    if (element) {
      element.scrollIntoView();
    }
  }
};
</script>