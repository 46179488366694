<template>
  <footer class="pt-5 pb-3 bg-dark">
    <div class="container-lg">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="max-width-280 mx-auto m-md-0">
            <a class="" href="/">
              <img :src="require('../assets/images/logo_orange_door.png')" alt="logo" class="img-fluid">
            </a>
          </div>
          <div class="mt-3 text-light text-center-md">
            <p class="d-flex align-items-center justify-content-center justify-content-md-start ms-md-2">
              <span class="d-block square-25 me-2">
                <i class="fa-solid fa-envelope"></i>
              </span>
              <a href="mailto: info@kodishamlango.com" class="text-light d-inline-block">info@kodishamlango.com</a>
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-8">
          <div class="row">
            <div class="col-sm-6">
              <ul class="list-unstyled text-center mt-4">
              <li v-for="(link, index) in footer_main_links" :key="index">
                <a :href="link.url" class="text-light text-decoration-none">{{link.name}}</a>
              </li>
            </ul>
            </div>
            <div class="col-sm-6">
              <ul class="list-unstyled text-center mt-4">
                <li v-for="(link, index) in footer_more_links" :key="index">
                  <a :href="link.url" class="text-light text-decoration-none">{{link.name}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="my-3">
            <p v-for="i in top_links" :key="i" class="d-inline-block pe-2 mb-2" v-html="updateCommonApartmentLinks(i)">
            </p>
          </div>
        </div>
        <div class="col-12 mt-3">
          <hr class="bg-light" />
          <div class="mt-4">
            <p class="d-none text-center">
              <span class="d-block">Rent, Buy, Sell Real Estate Properties, Business Properties, Housing Projects, Apartments, Flats, Homes, Houses, Plots for free across Kenya. Kodishamlango.com provides easy way to rent, buy and sell your properties.</span>
              <span class="d-block">Kodisha, Nunua, Uza Ghorofa, Magorofa, Nyumba bila malipo kote nchini Kenya. Kodishamlango.com hutoa njia rahisi ya kukodisha, kununua na kuuza mali yako.</span>
            </p>
            <p class="text-light text-center fs-12">
              Copyright &copy; {{ new Date().getFullYear() }}
              <a href="/" class="text-light text-decoration-none"
                >Kodishamlango.com</a
              >
              All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      footer_main_links: [
        {
          name: "Home page",
          url: "/"
        },
        {
          name: "Homes for Rent",
          url: "/houses-for-rent"
        },
        {
          name: "Buy home",
          url: "/houses-for-sell"
        },
        {
          name: "Short stay (Holiday)",
          url: "/short-stay"
        },
        {
          name: "New Advertisement",
          url: "/new-advertisement"
        },
        {
          name: "About us",
          url: "/about-us"
        },
        {
          name: "Contact",
          url: "/contact-us"
        },
        {
          name: "Terms and Conditions",
          url: "/terms-and-conditions"
        },
        {
          name: "Privacy",
          url: "/privacy"
        },
        {
          name: "How to register",
          url: "/how-to-register"
        }
      ],
      footer_more_links: [
        {
          name: "Apartments",
          url: "/apartments"
        },
        {
          name: "Furnished Apartments",
          url: "/fully-furnished-apartments"
        },
        {
          name: "Property For Rent",
          url: "/property-for-rent"
        },
        {
          name: "Homes For Sale",
          url: "/homes-for-sale"
        },
        {
          name: "Homes For Rent",
          url: "/homes-for-rent"
        },
        {
          name: "Property To Let",
          url: "/property-to-let"
        },
        {
          name: "Apartments For Rent in Nairobi",
          url: "/apartments-for-rent-in-nairobi"
        },
        {
          name: "Apartments For Sale in Nairobi",
          url: "/apartments-for-sale-in-nairobi"
        },
        {
          name: "Properties For Sale in Mombasa",
          url: "/properties-for-sale-in-mombasa"
        },
        {
          name: "Properties For Rent in Mombasa",
          url: "/properties-for-rent-in-mombasa"
        }
      ],
      top_links: [1,2,3,4,5,6]
    };
  },
  methods: {
    construct_link_name(name){
      return name.split("-").map(n => n).join(" ")
    },
    updateCommonApartmentLinks(index){
      const apt_types = ["home", "house", "apartment"]
      const rand = Math.floor(Math.random() * 3);
      const selected = apt_types[rand]
      return `<a class="text-capitalize text-white" href="/${index}-bedroom-${selected}s">${this.construct_link_name(`${index}-bedroom-${selected}s`)}</a>`
    }
  }
};
</script>
